import { defineStore } from 'pinia';
import type { User } from '@/graphql/generated/graphql';

export const useCurrentUserDataStore = defineStore('currentUserData', () => {
  const currentUserData = ref<User | null>(null);

  function setCurrentUserData(userData: User | null) {
    currentUserData.value = userData;
  }

  return {
    currentUserData,
    setCurrentUserData
  };
});
